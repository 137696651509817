import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog39.jpg'
import styles from './post-grid.module.css'

const FoodChooseRestaurant = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jak si vybírat jídlo v restauracích?'}
        description={
          'Přes týden máte krabičky a víte tedy, co a jak. Příjem máte nastavený, živiny také a tak není co řešit.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/food-choose-restaurant">
            Jak si vybírat jídlo v restauracích?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Jak si vybírat jídlo v restauracích?"
              date="13.09.23"
            />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>
                Přes týden máte krabičky a víte tedy, co a jak. Příjem máte
                nastavený, živiny také a tak není co řešit. Přijde ovšem víkend
                a vám se nechce vařit, rozhodnete se proto jít do restaurace.
                Jelikož se přes týden stravujete zdravě, nemáte potřebu si
                “vyhodit z kopýtka”, a proto se i zde snažíte vybrat nějaké
                jídlo, které zapadne do vašeho životního stylu.
                <b>
                  {' '}
                  Jak ale na to? Zkuste dodržovat pár základních pravidel.{' '}
                </b>
              </p>
              <h5 className={styles.postTitle}>Výběr restaurace</h5>
              <p className={styles.postText}>
                První otázkou je,<b> do jaké restaurace se vůbec vydat</b>?
                Vhodné jsou například restaurace specializované na středomořskou
                kuchyni, kde najdete různě upravené ryby, mořské plody, saláty,
                odlehčené přílohy a tepelně upravenou zeleninu.
                <b>
                  {' '}
                  Vybrat si jídlo, které splňuje kritéria správného stravování,
                  se však dá téměř v každé restauraci
                </b>
                , takže si jednoduše vyberte kuchyni, na kterou máte chuť.
              </p>
              <h5 className={styles.postTitle}>
                Jaká jsou základní pravidla zdravé výživy?
              </h5>
              <p className={styles.postText}>
                Aby nám strava poskytla vše, co naše tělo potřebuje, musí
                obsahovat<b> základní živiny, </b>tedy tuky, sacharidy a
                bílkoviny ale i tzv.<b> mikronutrienty</b>, tedy vitamíny a
                minerální látky. Dále bychom měli v rámci jídelníčku a pitného
                režimu přijímat v<b> dostatečné míře tekutiny</b>. Obecně platí,
                že bychom měli jíst víceméně všechno, aby naše strava byla
                pestrá, ale současně je potřeba dodržovat zásady správné výživy,
                tzn. nepřijímat ve velkém množství například živočišné tuky,
                jednoduché cukry a podobně.
                <b> Nemusíme se tedy vzdávat oblíbených jídel a potravin </b>– u
                některých můžeme lehce obměnit složení, jiné zařadíme méně
                často. Kvalitní potraviny často nebývají ani dražší než
                potraviny nekvalitní.
              </p>

              <p className={styles.postText}>
                <i><b>TIP</b></i>
                <i>
                  : Chcete se dostat na{' '}
                  <a href="https://nutritionpro.cz/blog/lose-weight/">
                    {' '}
                    léto do formy
                  </a>
                </i>
                <i>? S krabičkami to půjde samo!</i>
              </p>
              <h5 className={styles.postTitle}>
                Jak si vybrat vhodné jídlo v restauracích?
              </h5>
              <p className={styles.postText}>
                Náš talíř by měla cca z
                <b>
                  {' '}
                  30 % tvořit zelenina či ovoce, z 30 % komplexní sacharidy a 30
                  % bílkoviny, zbytek{' '}
                </b>
                by měly tvořit<b> tuky, </b>ideálně<b> rostlinné. </b>Pokud je
                na vašem talíři zeleniny nedostatek, objednejte si ještě malý
                <b> salát. </b>Příkladem komplexních sacharidů je celozrnné
                pečivo, brambory, rýže nebo těstoviny. Pokud si můžete přílohu
                zvolit, chtějte ideálně některou z těchto. Také volte co
                nejšetrnější tepelnou úpravu. Pokud máte možnost zvolit si
                <b> hranolky či vařené brambory</b>, sáhněte po těch vařených.
                Co se týče bílkovin, nejlepší je výběr z libových mas (kuřecí,
                krůtí, vepřová panenka, kýta, hovězí) a ryby nebo vegetariánské
                pokrmy obsahující tofu, sóju a luštěniny. Obecně je vhodné se
                <b> vyvarovat smaženým jídlům </b>(smažený řízek, sýr,
                hranolky),<b> tučným omáčkám </b>(smetanovým nebo sýrovým) i
                <b> sladkým pokrmům. </b>Čas od času mají tyto pokrmy ve vašem
                jídelníčku také místo, ale rozhodně by se tam neměly vyskytovat
                pravidelně.{' '}
              </p>
              <h5 className={styles.postTitle}>Pozor na polévky</h5>
              <p className={styles.postText}>
                Polévky se obecně jedí před hlavním jídlem, aby vám{' '}
                <b> nastartovaly trávení. </b>Není však na místě, aby se váš
                oběd či večeře skládaly <b> pouze a jen z polévky</b>. Ve
                většině případů bude totiž<b> chybět zdroj bílkovin. </b>Polévka
                taky obvykle neobsahuje dostatečné množství energie, aby
                nahradila hlavní jídlo.{' '}
              </p>
              <p className={styles.postText}>
                Když si polévku dáte, vybírejte nezahuštěné varianty (vývary
                nebo zeleninové polévky bez jíšky a smetany). Pokud si dáte
                např. gulášovou nebo zahuštěnou zeleninovou polévku, hlavní chod
                již stačí v lehčí formě, například salát caesar.
              </p>
              <h5 className={styles.postTitle}>A co sladké pokrmy?</h5>
              <p className={styles.postText}>
                Sladké pokrmy jsou<b> bohaté na jednoduché cukry, </b>obsahují
                velké množství tuků a obvykle i<b> nízký obsah bílkovin, </b>{' '}
                díky čemuž vás jídlo moc nenasytí a brzy budete mít zase hlad.
                Je tedy lepší si takové jídlo připravit doma, pokud na něj máte
                vyloženě chuť, a tím si ohlídat dostatečné bílkovinné zdroje i
                množství cukru a tuků.
              </p>
              <p className={styles.postText}>
                <i>
                  {' '}
                  <b>TIP</b>: Podívejte se,{' '}
                  <a
                    href="https://nutritionpro.cz/blog/sirupy/"
                    target="_blank"
                  >
                    {' '}
                    jaký sirup použít{' '}
                  </a>
                  , <i> až si budete dělat něco sladkého doma!</i>
                </i>
              </p>


              <p className={styles.postText}></p>
              <h5 className={styles.postTitle}>Čínská, vietnamská, japonská, česká....</h5>

              <p className={styles.postText}>
                Máte rádi asijské restaurace? Nebo raději tu českou? Podívejme
                se na 4 konkrétní světové kuchyně a co si z nich vybrat.
              </p>

              <p className={styles.postText}>
                I přes to, že<b> čínské restaurace </b>servírují i nesmažené
                pokrmy, maso je připravováno na velkém množství oleje, a omáčky
                obsahují hodně škrobu. Pokud tedy takovou restauraci navštívíte,
                měli byste si vybírat spíše<b> nesmažená jídla</b>, třeba s
                kuřecím masem, rýží a zeleninou. Ve<b> vietnamských </b>
                restauracích můžete sáhnout po<b> Pho polévce. </b>Tyto polévky
                totiž obsahují masový vývar, zeleninu, rýžové nudle, maso a
                dokáží tak nahradit plnohodnotné hlavní jídlo.<b> Japonská </b>
                restaurace nenabízí pouze sushi, ale můžete si dát například
                <b> polévku z pasty ze sójových bobů, tofu a mořskou řasou. </b>
                Mořské řasy obsahují omega 3 mastné kyseliny, jód, vápník a
                vitamín B, takže jsou výborné jako příloha. Pokud máte chuť na
                sushi, nedoporučuje se dávat si smažené, ale spíše to, ve kterém
                je méně rýže a více bílkovinné složky jako jsou například ryby.
                U<b> české </b> kuchyně je dobré zvolit variantu vývarů, hovězí
                nebo vepřové steaky, vařené brambory a salát. Pokud je jídlo v
                jídelním lístku nakombinované tak, že obsahuje steak s
                hranolkami, nebojte se místo nich požádat o vařené brambory.
              </p>
              <h5 className={styles.postTitle}>A co je závěrem?</h5>

              <p className={styles.postText}>
                Občasné stravování v restauracích
                <b> není proti zdravému životnímu stylu, </b>pokud jsou
                dodržovány zásady správné výživy. Restaurace zákazníkovi
                <b> vycházejí vstříc </b>a bez problému ochotně např. změní
                přílohu, případně<b> upraví jídlo </b> dle vašeho požadavku.
                Není tedy nutné mít z těchto podniků obavy, ale naopak je
                příjemné si vychutnávat jejich nabídky.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default FoodChooseRestaurant
